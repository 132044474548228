import logo from './assets/images/Jatra-Spirits-Logo.png';
import SazeracLogo from './assets/images/Sazerac-Logo.png';
import brandLogos from './assets/images/Jatra-Spirits_website-banner.jpg';
import './App.css';

function App() {
  return (
    <div className="js-app flex flex-col min-h-screen">
      <div className="container mx-auto text-center">
      <header className="js-header overflow-hidden py-4 mb-4 border-b border-slate-200">
       
          <div className="js-logo inline-block">
            <img src={logo} title="Jatra Spirits" alt="Jatra Spirits Logo" />
          </div>
        
      </header>
      </div>
      <div className="clear-both"></div>
      <main>
        <div className="container mx-auto text-center">
         
         
          
          <img className="inline-block my-4 mx-w-3xl js-sazerac" src={SazeracLogo} alt="Sazerac Logo" />
          {/* <span className='block text-xl'>Jatra Spirits is an authorized importer and distributor of products of Sazerac in Nepal.</span> */}
          <div className="clear-both"></div>
            <img className="inline-block mb-4" src={brandLogos} alt="Brand Logos" />
          
          {/* <div className="mx-auto max-w-3xl font-display text-5xl font-medium tracking-tight py-4">
            <span className="text-2xl block">Imported & Distributed</span>
            <span className="text-2xl block">by</span>
            <h2 className='text-3xl'>Jatra Spirits Pvt. Ltd.</h2>
          </div> */}
        </div>
      </main>

      <footer className="text-sm leading-6 sticky mt-auto">
        <div className="container mx-auto text-center">
          <div className="py-4 border-t border-slate-200 sm:flex justify-center text-slate-500 dark:border-slate-200/5">
            <div className="mb-6 sm:mb-0 sm:flex">
              <p>© 2024 Jatra Spirits Pvt. Ltd.</p>
            </div>
          </div>
        </div>
      </footer>

      
    </div>
  );
}

export default App;
